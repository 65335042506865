$(function () {
  let halfWindowHeight = window.innerHeight / 2;
  let pointImages = $('.point__image');
  let pointImagesArray = Array.prototype.slice.call(pointImages, 0);

  let calloutImages = $('.callout-image');
  let calloutImagesArray = Array.prototype.slice.call(calloutImages, 0);

  if (
    ! halfWindowHeight ||
    ! pointImagesArray ||
    ! calloutImagesArray
  ) return;

  AddAnimationFadeIn();

  document.onscroll = function () {
    PointImageAddAnimationSlide();
    AddAnimationFadeIn();
  }

  function PointImageAddAnimationSlide() {
    let criterionLine = this.pageYOffset + halfWindowHeight;
    pointImagesArray.forEach(function(e) {
      let imageObjectTop = $(e).offset().top;
      if (imageObjectTop > criterionLine) return;
      e.dataset.animation = 'slide';
    });
  }

  function AddAnimationFadeIn() {
    let criterionLine = this.pageYOffset + halfWindowHeight;
    calloutImagesArray.forEach(function (e) {
      let imageObjectTop = $(e).offset().top;
      if (imageObjectTop > criterionLine) return;
      e.dataset.animation = 'fade-in';
    });
  }

  $('.trial__calendar-block').slick({
    arrows:           true,
    slidesToShow:        1,
    centerMode:       true,
    centerPadding:  '25px',
    infinite:         true,
    swipe:            true,
    accessibility:    true,
    focusOnSelect:    true,
  });
});